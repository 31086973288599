import React from 'react';
import slugify from 'slugify';

import BreadcrumbsItem from './BreadcrumbsItem/BreadcrumbsItem';

import styles from './BreadcrumbsActivity.module.scss';

const BreadcrumbsActivity = ({
  country, region, city, district, name, isActivity = true,
}) => (
  <div className={styles.breadcrumbsWrapper}>
    <BreadcrumbsItem
      href={`/${isActivity ? 'activities' : 'properties'}/results/?countryId=${country?.id}&name=${country?.name}`}
      name={country?.name}
      isActive={!region?.id}
    />
    {region?.id && (
    <BreadcrumbsItem
      href={`/${isActivity ? 'activities' : 'properties'}/results/?countryId=${country?.id}&regionId=${region?.id}&name=${slugify(region?.name)}`}
      name={region?.name}
      isActive={!city?.id && !name}
    />
    )}
    {city?.id && (
    <BreadcrumbsItem
      href={`/${isActivity ? 'activities' : 'properties'}/results/?countryId=${country?.id}&regionId=${region?.id}&cityId=${city?.id}&name=${slugify(city?.name)}`}
      name={city?.name}
      isActive={!district?.id && !name}
    />
    )}
    {district?.id && (
    <BreadcrumbsItem
      href={`/${isActivity ? 'activities' : 'properties'}/results/?countryId=${country?.id}&regionId=${region?.id}&cityId=${city?.id}&districtId=${district?.id}&name=${slugify(district?.name)}`}
      name={district?.name}
      isActive={!name}
    />
    )}
    {name && (
    <BreadcrumbsItem
      href={`/${isActivity ? 'activities' : 'properties'}/results/?countryId=${country?.id}&regionId=${region?.id}&cityId=${city?.id}&districtId=${district?.id}&name=${slugify(name)}`}
      name={name}
      isLast
      isActive={name}
    />
    )}
  </div>
);

export default BreadcrumbsActivity;
